import TransactionService from './transaction.service'

export default {
  fetchDefinitionWithObject (config, obj_type, gpk) {
    const ta = [
      { name: 'cntl.ot_attr_def.list', old: { object_type_fq_name: obj_type } },
      { name: 'cntl.ot_attr_val.list', old: { object_gfk: gpk } },
      { name: 'cntl.ot_attr_key.list', old: { object_type_fq_name: obj_type } },
    ]
    return TransactionService.execute(config, ta)
  },
}
