<template>
  <div id="obj_viewer">
    <Loading :data="obj">
      <template v-if="obj">
        <b-row>
          <b-col>
            <h1>{{ $t('views.object_explorer.object_explorer') }} <small v-if="obj_type" class="text-muted">{{
                obj_type.object_type_fq_name
              }}</small></h1>
          </b-col>
          <b-col lg="2">
            <b-button-group class="d-flex">
              <b-button id="editobj" variant="outline-primary"
                        v-b-modal:object_editor class="mt-3 shadow">
                <netvs-icon icon="edit"></netvs-icon>
              </b-button>
              <b-tooltip target="editobj" triggers="hover"
                         variant="primary" placement="bottom">
                {{ $t('system.edit') }}
              </b-tooltip>
              <b-button variant="outline-secondary" id="objview_evlog"
                        v-b-modal:object_viewer_evlog class="mt-3 shadow">
                <netvs-icon icon="evlog"></netvs-icon>
              </b-button>
              <b-tooltip target="obview_evlog" triggers="hover"
                         variant="secondary" placement="bottom">
                {{ $t('system.event_log') }}
              </b-tooltip>
            </b-button-group>
          </b-col>
        </b-row>
        <b-table-simple responsive>
          <b-tr v-for="(v,k) in obj" :key="v+':'+k">
            <b-th>{{ k }}<br><span class="text-muted">{{ obj_definition.attributes[k].description_detail }}</span>
            </b-th>
            <b-td>{{ v }}</b-td>
            <b-td>
              <b-button v-if="k in obj_definition_ref"
                        @click="join(obj_definition_ref[k].system+'.'+obj_definition_ref[k].object_type, 'default')">
                {{ $t('views.object_explorer.join_to') }}
                {{ obj_definition_ref[k].system }}.{{ obj_definition_ref[k].object_type }}
              </b-button>
            </b-td>
          </b-tr>
        </b-table-simple>
        <h2>{{ $t('views.object_explorer.object_attributes') }}</h2>
        <b-table-simple responsive>
          <b-tr v-for="a in attr_defs" :key="a.key_word">
            <b-th>{{ a.name }} <code>{{ a.key_word }}</code><br/><span class="text-muted">{{ a.description }}</span>
            </b-th>
            <b-td>
              <ul>
                <li v-for="v in attr_kv[a.key_word]" :key="v.object_gfk + '_' + v.ot_attr_def_key_word + '_' + v.value">
                  <code>{{ v.value }}</code> (
                  <b-link :to="'/object/'+v.ref_object_gfk">{{ $t('system.ref') }}</b-link>
                  )
                </li>
              </ul>
            </b-td>
          </b-tr>
        </b-table-simple>
        <h2>{{ $t('views.object_explorer.constraints') }}</h2>
        <b-table-simple responsive>
          <b-tr>
            <b-th>{{ $t('system.name') }}</b-th>
            <b-th>{{ $t('views.object_explorer.attributes') }}</b-th>
          </b-tr>
          <b-tr v-for="(v,k) in obj_definition.constraints" :key="v+':'+k">
            <b-td>{{ k }}<br><span class="text-muted">{{ v.description }}</span></b-td>
            <b-td>
              <ul>
                <li v-for="a in v.attributes" :key="k+':'+a">{{ a }}</li>
              </ul>
            </b-td>
          </b-tr>
        </b-table-simple>
        <h2>{{ $t('views.object_explorer.dump') }}</h2>
        <CopyField multiline :text="obj_dump"></CopyField>
        <DBEditor :object_fq_name="obj_type.object_type_fq_name" object_function="update"
                  modal_id="object_editor" :old_data="obj" :presets="obj" :non_optionals="Object.keys(obj)"
                  :object_title="obj.gpk"></DBEditor>
        <EVLogViewer modal_id="object_viewer_evlog" :ref_obj_fq="obj_type.object_type_fq_name"
                     :refobj_id_value="obj.gpk" :title="obj.gpk"></EVLogViewer>
        <b-modal id="joiner" ok-only
                 :title="$t('views.object_explorer.join_a_to_b', {a: obj_type.object_type_fq_name, b: join_target})">
          {{ $t('views.object_explorer.results', { constraint: join_constraint }) }}
          <ul>
            <li v-for="r in join_res" :key="r.gpk">
              <b-link :to="'/object/'+r.gpk"><code>{{ join_res }}</code></b-link>
            </li>
          </ul>
          <b-table></b-table>
        </b-modal>
      </template>
    </Loading>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import GPK2ObjService from '@/api-services.gen/cntl.global_pk2obj'
import WapiTransactionService from '@/api-services/transaction.service'
import ObjAttribService from '@/api-services/obj_attribs'
import EVLogViewer from '@/components/EVLogViewer'
import Transactionutil from '@/util/transactionutil'
import CopyField from '@/components/CopyField'
import apiutil from '@/util/apiutil'

export default {
  name: 'ObjectViewer',
  components: { CopyField, EVLogViewer, DBEditor, Loading },
  data() {
    return {
      obj_type: null,
      obj: null,
      obj_dump: null,
      gpk: null,
      obj_definition: null,
      obj_definition_ref: null,
      join_target: null,
      join_constraint: null,
      join_res: null,
      attr_defs: null,
      attr_kv: null
    }
  },
  watch: {
    $route() {
      this.obj_type = this.obj = null
      this.gpk = this.$route.params.gpk
      this.fetch()
    }
  },
  async created() {
    this.gpk = this.$route.params.gpk
    await this.fetch()
  },
  methods: {
    async fetch() {
      this.obj_type = (await GPK2ObjService.list(this.$store.state, { gpk: this.gpk })).data[0][0]
      this.obj_definition = (await Transactionutil.getObjectMetadata(this, this.obj_type.object_type_fq_name)).object_definition
      this.obj_definition_ref = (await Transactionutil.getObjectMetadata(this, this.obj_type.object_type_fq_name)).object_definition_referencing_by_attribute
      await this.fetchAttribs()
      this.obj = this.obj_type.object_dict
      this.obj_dump = JSON.stringify(this.obj, null, 4)
      this.$store.commit('setNavigationRefreshHandle', {
        gpk: this.obj.gpk,
        objType: this.obj_type.object_type_fq_name
      })
    },
    async fetchAttribs() {
      const res = (await ObjAttribService.fetchDefinitionWithObject(this.$store.state, this.obj_type.object_type_fq_name, this.gpk)).data
      this.attr_defs = res.ot_attr_def_list
      this.attr_kv = apiutil.dict_of_lists_by_value_of_array(res.ot_attr_val_list, 'ot_attr_def_key_word')
    },
    async join(target, constraint) {
      this.join_constraint = constraint
      this.join_target = target
      this.join_res = (await WapiTransactionService.executeDry(this.$store.state, [
        { name: `${this.obj_type.object_type_fq_name}.list`, old: { gpk: this.obj.gpk } },
        { name: `${target}.list`, inner_join_ref: { 0: constraint } }
      ])).data[1]
      this.$root.$emit('bv::show::modal', 'joiner')
    }
  }
}
</script>

<style scoped>

</style>
